import { render, staticRenderFns } from "./EchartsBase.vue?vue&type=template&id=f764d85e&scoped=true&"
import script from "./EchartsBase.vue?vue&type=script&lang=js&"
export * from "./EchartsBase.vue?vue&type=script&lang=js&"
import style0 from "./EchartsBase.vue?vue&type=style&index=0&id=f764d85e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f764d85e",
  null
  
)

export default component.exports