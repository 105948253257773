<template>
  <div class="system-menu auto">
    <dl v-if="menu['system'].indexOf('/system/analysis/index') > -1 || menu['system'].indexOf('/system/analysis/face') > -1 || menu['system'].indexOf('/system/analysis/login') > -1 || menu['system'].indexOf('/system/analysis/active') > -1 || menu['system'].indexOf('/system/analysis/ip') > -1">
      <dt>统计分析</dt>
      <dd v-if="menu['system'].indexOf('/system/analysis/index') > -1"><router-link to="/system/analysis/index" :class="path === '/system/analysis/index' && 'cur'">系统概况</router-link></dd>
      <dd v-if="menu['system'].indexOf('/system/analysis/face') > -1"><router-link to="/system/analysis/face" :class="path === '/system/analysis/face' && 'cur'">人脸识别</router-link></dd>
      <dd v-if="menu['system'].indexOf('/system/analysis/login') > -1"><router-link to="/system/analysis/login" :class="path === '/system/analysis/login' && 'cur'">用户登录</router-link></dd>
      <dd v-if="menu['system'].indexOf('/system/analysis/active') > -1"><router-link to="/system/analysis/active" :class="path === '/system/analysis/active' && 'cur'">访问量</router-link></dd>
      <dd v-if="menu['system'].indexOf('/system/analysis/ip') > -1"><router-link to="/system/analysis/ip" :class="path === '/system/analysis/ip' && 'cur'">登录IP</router-link></dd>
    </dl>
    <dl v-if="menu['system'].indexOf('/system/power/index') > -1 || menu['system'].indexOf('/system/power/roles') > -1">
      <dt>权限管理</dt>
      <dd v-if="menu['system'].indexOf('/system/power/index') > -1"><router-link to="/system/power/index" :class="path === '/system/power/index' && 'cur'">人员授权</router-link></dd>
      <dd v-if="menu['system'].indexOf('/system/power/roles') > -1"><router-link to="/system/power/roles" :class="path === '/system/power/roles' && 'cur'">角色管理</router-link></dd>
    </dl>
    <dl v-if="menu['system'].indexOf('/system/interface/index') > -1 || menu['system'].indexOf('/system/interface/statistics') > -1 || menu['system'].indexOf('/system/interface/api') > -1">
      <dt>接口管理</dt>
      <dd v-if="menu['system'].indexOf('/system/interface/index') > -1"><router-link to="/system/interface/index" :class="(path === '/system/interface/index' || path === '/system/interface/add') && 'cur'">应用管理</router-link></dd>
      <dd v-if="menu['system'].indexOf('/system/interface/statistics') > -1"><router-link to="/system/interface/statistics" :class="path === '/system/interface/statistics' && 'cur'">调用统计</router-link></dd>
      <dd v-if="menu['system'].indexOf('/system/interface/api') > -1"><router-link to="/system/interface/api" :class="path === '/system/interface/api' && 'cur'">接口统计</router-link></dd>
    </dl>
    <dl v-if="menu['system'].indexOf('/system/configuration/index') > -1 || menu['system'].indexOf('/system/configuration/index') > -1">
      <dt>系统配置</dt>
      <dd v-if="menu['system'].indexOf('/system/configuration/accesspolicy') > -1"><router-link to="/system/configuration/accesspolicy" :class="path === '/system/configuration/accesspolicy' && 'cur'">系统准入策略</router-link></dd>
      <dd v-if="menu['system'].indexOf('/system/configuration/index') > -1"><router-link to="/system/configuration/index" :class="path === '/system/configuration/index' && 'cur'">账号安全策略</router-link></dd>
      <!-- <dd><router-link to="/system/configuration/syslog" :class="path === '/system/configuration/syslog' && 'cur'">syslog配置</router-link></dd> -->
      <dd v-if="menu['system'].indexOf('/system/configuration/basic') > -1"><router-link to="/system/configuration/basic" :class="path === '/system/configuration/basic' && 'cur'">基本配置</router-link></dd>
    </dl>
    <dl v-if="menu['system'].indexOf('/system/backup/index') > -1 || menu['system'].indexOf('/system/backup/external') > -1">
      <dt>数据备份</dt>
      <dd v-if="menu['system'].indexOf('/system/backup/index') > -1"><router-link to="/system/backup/index" :class="path === '/system/backup/index' && 'cur'">导入导出</router-link></dd>
      <dd v-if="menu['system'].indexOf('/system/backup/external') > -1"><router-link to="/system/backup/external" :class="path === '/system/backup/external' && 'cur'">导入外部数据</router-link></dd>
    </dl>
    <dl v-if="menu['system'].indexOf('/system/other/message') > -1 || menu['system'].indexOf('/system/other/task') > -1">
      <dt>其它设置</dt>
      <dd v-if="menu['system'].indexOf('/system/other/message') > -1"><router-link to="/system/other/message" :class="path === '/system/other/message' && 'cur'">消息提醒</router-link></dd>
      <dd v-if="menu['system'].indexOf('/system/other/task') > -1"><router-link to="/system/other/task" :class="path === '/system/other/task' && 'cur'">定时任务</router-link></dd>
    </dl>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SystemMenu',
  data () {
    return {
      path: 'index'
    }
  },
  watch: {
    '$route': function (val) {
      this.path = val.path
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'menu'
    ])
  },
  methods: {
  },
  created () {
    this.path = this.$route.path
  }
}
</script>

<style scoped>
.system-menu {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.system-menu dl dt {
  font-size: 20px;
  color: rgba(0,0,0,0.25);
  text-indent: 42px;
  font-weight: bold;
  line-height: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.system-menu dl dd{
  width: 100%;
  margin: 0;
}
.system-menu dl dd a {
  display: inline-block;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-indent: 42px;
  font-size: 16px;
  color: rgba(0,0,0,0.65);
  margin: 0;
}
/*.system-menu dl dd a:hover {
  background: #E6F7FF;
}*/
.system-menu dl dd a.cur {
  background: #E6F7FF;
  color: rgba(0,0,0,0.85);
  font-weight: bold;
}
</style>
