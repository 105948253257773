<template>
  <div class="customPage">
    <div class="page-left fl">
      <slot name="custom"></slot>
    </div>
    <div class="page-right fr">
      <!-- <a-pagination show-quick-jumper :default-current="1" :total="500" size="small" @change="onChange" /> -->
      <a-pagination size="small" v-model="current_page" :pageSize="current_limit" :total="total" :show-size-changer="hideSize ? false : true" show-quick-jumper @change="onChange" @showSizeChange="showSizeChange" />
    </div>
  </div>
</template>

<script>
import { Filter } from '@/model'
export default {
  name: 'CustomPage',
  props: [
    'total',
    'page',
    'limit',
    'hideSize'
  ],
  data () {
    return {
      current_total: 0,
      current_page: 1,
      current_limit: 10
    }
  },
  watch: {
    'page': function (val) {
      this.current_page = val
    },
    'limit': function (val) {
      this.current_limit = val
    },
    'total': function (val) {
      this.current_total = val
    }
  },
  methods: {
    onChange (pageNumber, pageSize) {
      // console.log('Page: ', pageNumber, pageSize);
      this.$emit('getPageMes', {
        pageNumber: pageNumber,
        pageSize: pageSize
      })
    },
    showSizeChange (pageNumber, pageSize) {
      // console.log('Page: ', pageNumber, pageSize);
      this.$emit('getPageMes', {
        pageNumber: pageNumber,
        pageSize: pageSize
      })
    }
  },
  created () {
    if (!this.page) {
      this.current_page = 1
    }
    if (!this.limit) {
      this.current_limit = 10
    }
    if (!this.total) {
      this.current_total = 10
    }
  }
}
</script>

<style scoped>
</style>
