import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Util from './common/util'
import Config from './conf'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import VueCookie from 'vue-cookie'
import '../public/theme/index.css'
import { Cascader } from 'element-ui';

let errMsg = require('../public/err_msg/error.json')
// let baseConfig = require('../public/config/config.json')

Vue.config.productionTip = false

const requireComponent = require.context(
  // 其组件目录的相对路径
  './components/common',
  // 是否查询其子目录
  false,
  // 匹配基础组件文件名的正则表达式
  /[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName)

  // 全局注册组件
  componentConfig.default.install = function (Vue) {
    Vue.component(componentConfig.default.name, componentConfig.default)
  }

  Vue.use(componentConfig.default)
})

Vue.prototype.util = Util
Vue.prototype.config = Config
Vue.prototype.errMsg = errMsg
// Vue.prototype.baseConfig = baseConfig

Vue.use(Antd)
Vue.use(VueCookie)
Vue.use(Cascader)

let vueMain = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default vueMain
