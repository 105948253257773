import Http from '@/common/http'
export default class Group {
  static getDepartment () {
    return Http('get', 'api/code/department/list')
  }
  static modifyDepartment (id, param = {}) { // 修改
    return Http('put', 'api/code/department/detail/' + id, param)
  }
  static addDepartment (param = {}) { // 新增
    return Http('post', 'api/code/department/detail', param)
  }
  static deleteDepartment (id, param = {}) { // 修改
    return Http('delete', 'api/code/department/detail/' + id, param)
  }
  static sortDepartment (param = {}) {
    return Http('put', 'api/code/department/sort', param)
  }
  static getUserType () {
    return Http('get', 'api/code/usertype/list')
  }
  static modifyUserType (id, param = {}) { // 修改
    return Http('put', 'api/code/usertype/detail/' + id, param)
  }
  static addUserType (param = {}) { // 新增
    return Http('post', 'api/code/usertype/detail', param)
  }
  static deleteUserType (id, param = {}) { // 修改
    return Http('delete', 'api/code/usertype/detail/' + id, param)
  }
  static sortUserType (param = {}) {
    return Http('put', 'api/code/usertype/sort', param)
  }
  static getTag (type) {
    return Http('get', 'api/tag/list')
  }
  static getTagDetail (id) {
    return Http('get', 'api/tag/detail/' + id)
  }
  static changeTag (id, param = {}) {
    return Http('put', 'api/tag/detail/' + id, param)
  }
  static deleteTag (id, param = {}) {
    return Http('delete', 'api/tag/detail/' + id, param)
  }
  static addTag (param = {}) {
    return Http('post', 'api/tag/detail', param)
  }
  static getTagUsed (id, param = {}) { // 删除标签前判断
    return Http('get', 'api/tag/service/' + id, param)
  }
  static getDynamicUser (id, param = {}) { // 修改
    return Http('get', 'api/tag/dynamic/user/' + id, param)
  }
  static getUploadStatus (id, param = {}) { // 修改
    return Http('get', 'api/tag/static/user/batch/status/' + id, param)
  }
  static getUploadResult (id, param = {}) { // 修改
    return Http('get', 'api/tag/static/user/batch/result/' + id, param)
  }
  static getStaticUser (id, param = {}) { // 修改
    return Http('get', 'api/tag/static/user/' + id, param)
  }
  static addTagUser (id, schoolid, param = {}) { // 修改
    return Http('post', 'api/tag/static/user/' + id + '/' + schoolid, param)
  }
  static deleteTagUser (id, userid, param = {}) { // 修改
    return Http('delete', 'api/tag/static/user/' + id + '/' + userid, param)
  }
  static getFilterList (type) {
    return Http('get', 'api/tag/filter/list')
  }
  static getFilterDetail (id, param = {}) {
    return Http('get', 'api/tag/filter/detail/' + id, param)
  }
  static addFilter (param = {}) { // 新增
    return Http('post', 'api/tag/filter/detail', param)
  }
  static changeFilter (id, param = {}) { // 修改
    return Http('put', 'api/tag/filter/detail/' + id, param)
  }
  static deleteFilter (id, param = {}) { // 删除
    return Http('delete', 'api/tag/filter/detail/' + id, param)
  }
  static getCode (type) {
    return Http('get', 'api/tag/code/' + type)
  }
}
