import Http from '@/common/http'
export default class Log {
  static getList (param = {}) {
    return Http('get', 'api/log/audit/query', param)
  }
  static getLoginList (param = {}) {
    return Http('get', 'api/log/login/query', param)
  }
  static getFaceList (param = {}) {
    return Http('get', 'api/log/facerecognition/query', param)
  }
  static getCode (type, param = {}) {
    return Http('get', 'api/log/code/' + type, param)
  }
  static getDetailList (param = {}) {
    return Http('get', 'api/log/login/query', param)
  }
  static getDetailList2 (param = {}) {
    return Http('get', 'api/log/login/query2', param)
  }
}
