<template>
  <div class="custom-date-picker">
    <a href="javascript:;" @click="tagId = 0" :class="tagId === 0 && 'cur'">今天</a>
    <a href="javascript:;" @click="tagId = 7" :class="tagId === 7 && 'cur'">近一周</a>
    <a href="javascript:;" @click="tagId = 30" :class="tagId === 30 && 'cur'">近一月</a>
    <a href="javascript:;" @click="tagId = 365" :class="tagId === 365 && 'cur'">近一年</a>
    <a-range-picker valueFormat="YYYY-MM-DD" v-model="date" size="small" style="width:224px;" @change="changeDate"><a-icon slot="suffixIcon" type="calendar" /></a-range-picker>
  </div>
</template>

<script>
import { Filter } from '@/model'
export default {
  name: 'CustomDatePicker',
  props: [
    'value'
  ],
  data () {
    return {
      date: [],
      tagId: 7
    }
  },
  watch: {
    'value': function (val) {
      this.date = val
    },
    'tagId': function (val) {
      var mode = ''
      switch (String(val)) {
        case '0' :
          mode = 'day'
          break;
        case '7' :
          mode = 'week'
          break;
        case '30' :
          mode = 'month'
          break;
        case '365' :
          mode = 'year'
          break;
        default :
          mode = 'search'
          break;
      }
      this.$emit('getMode', mode)
      this.$emit('getDate', this.getDate(val))
    }
  },
  methods: {
    changeDate (e) {
      // console.log(e)
      // const dateStringArr = [
      //   this.turnDate(e[0]),
      //   this.turnDate(e[1])
      // ]
      const dateStringArr = [
        e[0],
        e[1]
      ]
      this.$emit('getDate', dateStringArr)
    },
    turnDate (date) {
      let time = new Date(date)
      var y = time.getFullYear()
      var m = time.getMonth() + 1
      var d = time.getDate()
      return y + '年' + m + '月' + d + '日'
    },
    getDate (val) {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * Number(val))
      if (val !== '0') {
        // end.setTime(end.getTime() - 3600 * 1000 * 24 * 1) // 不包含今天
        end.setTime(end.getTime()) // 包含今天
      }
      // const dateStringArr = [
      //   this.turnDate(this.util.getDateString(start)),
      //   this.turnDate(this.util.getDateString(end))
      // ]
      const dateStringArr = [
        this.util.getDateString(start),
        this.util.getDateString(end)
      ]
      this.date = dateStringArr
      this.tagChange = true
      // this.dates = dateStringArr
      return dateStringArr
    }
  },
  created () {
  },
  mounted () {
    this.$emit('getDate', this.getDate(this.tagId))
  }
}
</script>

<style scoped>
.custom-date-picker a {
  color: rgba(0,0,0,0.65);
  margin-right: 15px;
}
.custom-date-picker a.cur {
  color: #1890FF;
}
</style>
