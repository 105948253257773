import Http from '@/common/http'
export default class Auth {
  static loginNative (param = {}) {
    return Http('post', 'api/login/native?data=' + param['data'])
  }
  static getUserinfo (param = {}) {
    return Http('get', 'api/userinfo', param)
  }
  static getMenu (param = {}) {
    return Http('get', 'api/menu', param)
  }
  static logout (param = {}) {
    return Http('get', 'api/logout', param)
  }
  // static updatePassword (param = {}) {
  //   return Http.post('/base/user/updatePassword', param)
  // }
  static updatePassword (param = {}) {
    return Http('post', 'api/passowrd/modify?data=' + param['data'])
  }
}
