<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <!-- <a-layout class="content" v-if="!user">
        <div class="login">
          <div class="login-content" v-show="isSysadm">
            <h3 class="login-title">统一身份认证系统</h3>
            <p>系统管理登录</p>
            <a-form-model :model="loginForm" :rules="loginFormRules" ref="loginForm" @keyup.enter.native="loginSubmit" style="width:340px;margin:20px auto 0;" :label-col="{span: 6}" :wrapper-col="{span: 16}">
              <a-form-model-item prop="username" label="账号">
                <a-input type="text" v-model.trim="loginForm.username" placeholder="请输入账号" ref="loginInput" />
              </a-form-model-item>
              <a-form-model-item prop="password" label="密 码">
                <a-input type="password" v-model.trim="loginForm.password" placeholder="请输入密码" />
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{span: 16, offset: 6}">
                <a-button type="primary" :spinning="btnLoading" @click="loginSubmit" style="width:50%;">登录</a-button>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
      </a-layout>
       v-else -->
      <a-layout class="content" v-if="user || router === '/login' || router === '/permission2'">
        <!-- <a-layout-header v-if="router !== '/login' && router !== '/permission'"> -->
        <a-layout-header v-if="router !== '/login' && router !== '/permission2'">
          <div class="header auto">
            <div class="logo fl auto">
              <img src="/logo_full_white.png" height="30">
              <!-- <img src="@/assets/logo.png"> -->
            </div>
            <div class="uesr-info fr auto">
              <!-- <span v-if="user && user.decentralization">
                <a href="javascript:;" @click="goDecentralization">二级授权</a>|
              </span>
              <span>{{user && user.realname}}</span>
              <a href="javascript:;" @click="logOut">退出</a> -->
              <span class="user-info-span">
                <!-- <a href="javascript:;" class="downloadlink" @click="downloadHandle">使用说明</a>| -->
                <a :href="config.root + 'file/instructions.MD'" target="_blank" download class="downloadlink" title="OpenAPI说明文档">使用说明</a>|
              </span>
              <a-dropdown>
                <span style="cursor: pointer;">{{user && user.realname}}&nbsp;<a-icon type="down" /></span>
                <a-menu slot="overlay">
                  <a-menu-item v-if="user && user.decentralization" @click="goDecentralization">
                    二级授权
                  </a-menu-item>
                  <a-menu-item @click="passwordVisible = true" v-if="user && user.native">
                    修改密码
                  </a-menu-item>
                  <a-menu-item @click="logOut">
                    退出
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
          <div class="nav auto">
            <!-- {{menu}} -->
            <ul>
              <li v-for="(item, index) in menu" :key="index" v-show="index !== 'decentralization' && index !== 'login' && index !== 'permission' && index !== 'permission2'">
                <router-link to="/user" :class="router.indexOf('/user/index') > -1 && 'current'" v-if="index === 'user'">人员管理</router-link>
                <router-link to="/access" :class="router.indexOf('/access') > -1 && 'current'" v-if="index === 'access'">系统接入</router-link>
                <router-link to="/group" :class="router.indexOf('/group') > -1 && 'current'" v-if="index === 'group'">分组管理</router-link>
                <router-link to="/system" :class="router.indexOf('/system') > -1 && 'current'" v-if="index === 'system'">系统管理</router-link>
                <router-link to="/logaudit" :class="router.indexOf('/logaudit') > -1 && 'current'" v-if="index === 'logaudit'">日志审计</router-link>
                <router-link to="/news" :class="router.indexOf('/news') > -1 && 'current'" v-if="index === 'news'">资讯发布</router-link>
              </li>
            </ul>
          </div>
        </a-layout-header>
        <a-layout-content>
          <router-view/>
        </a-layout-content>
      </a-layout>
      <a-modal v-model="passwordVisible" title="修改密码" @cancel="passwordCancel" @ok="passwordSubmit" width="434px">
        <div id="no-margin-form">
          <a-form-model
              ref="refPassForm"
              :rules="passRules"
              :model="passForm"
              class="ant-advanced-search-form"
              :label-col="{span: 6}"
              :wrapper-col="{span: 18}"
            >
            <a-form-model-item label="原始密码" prop="passwordOld">
              <a-input v-model="passForm.passwordOld" placeholder="请输入原始密码" size="small"/>
            </a-form-model-item>
            <a-form-model-item label="新密码" prop="passwordNew">
              <a-input type="password" v-model="passForm.passwordNew" placeholder="请输入新密码" size="small"/>
            </a-form-model-item>
            <a-form-model-item label="确认新密码" prop="passwordAgain">
              <a-input type="password" v-model="passForm.passwordAgain" placeholder="请再次输入新密码" size="small"/>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-modal>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { mapGetters } from 'vuex'
import { Auth } from '@/model'
import JSEncrypt from '@/common/jsencrypt.min'
export default {
  data () {
    return {
      baseConfig: window.config,
      router: '',
      locale: zhCN,
      isSysadm: true,
      menuRoute: {
        '01000000': 'user',
        '02000000': 'access',
        '03000000': 'group',
        '04000000': 'system',
        '05000000': 'logaudit',
        '06000000': 'news'
      },
      current: ['alipay'],
      loginForm: {
        username: 'admin',
        password: '1hans'
      },
      loginFormRules: {
        username: [
          {required: true, message: '请输入账号', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ]
      },
      btnLoading: false,
      passwordVisible: false,
      passForm: {
        passwordOld: '',
        passwordNew: '',
        passwordAgain: ''
      },
      passRules: {
        passwordOld: [
          { required: true, message: '请输入原始密码', trigger: 'blur' }
        ],
        passwordNew: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        passwordAgain: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' }
        ]
      },
      passloading: true
    }
  },
  watch: {
    '$route': function (val) {
      // console.log('app', val)
      if (val.path !== '/user/index') {
        this.$cookie.delete('batchid')
      }
      this.router = val.path
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'userModify',
      'userAccountModify',
      'menu'
    ])
  },
  methods: {
    downloadHandle () {
      window.open(this.config.root + 'file/instructions.MD', '_blank'); 
    },
    passwordCancel () {
      this.$refs['refPassForm'].clearValidate()
      this.passwordVisible = false
      this.passForm = {
        passwordOld: '',
        passwordNew: '',
        passwordAgain: ''
      }
    },
    passwordSubmit () {
      this.$refs['refPassForm'].validate( async valid => {
        if (valid) {
          this.passloading = true
          var encrypt = new JSEncrypt();
          encrypt.setPublicKey(this.config.pk);
          var param = {
            'passwordOld': this.passForm.passwordOld,
            'passwordNew': this.passForm.passwordNew,
            'timestamp': this.getTimeFn()
          }
          var sesecurityStr = encrypt.encrypt(JSON.stringify(param))
          let res = await Auth.updatePassword({
            data: encodeURIComponent(sesecurityStr)
          })
          this.passloading = false
          if (!res['code']) {
            this.$message.success('修改成功！')
            this.passwordCancel()
            this.logOut()
          } else {
            this.$message.error(this.errMsg[res['code']])
          }
        } else {
          return false;
        }
      })
    },
    goDecentralization () {
      this.$router.push('/decentralization')
    },
    async loginSubmit () {
      this.$refs['loginForm'].validate(async (valid) => {
        if (valid) {
          this.btnLoading = true
          var encrypt = new JSEncrypt();
          encrypt.setPublicKey(this.config.pk);
          var param = {
            'username': this.loginForm.username,
            'password': this.loginForm.password,
            'timestamp': this.getTimeFn()
          }
          // console.log(param)
          var sesecurityStr = encrypt.encrypt(JSON.stringify(param))
          // console.log(sesecurityStr)
          // console.log(encodeURIComponent(sesecurityStr))
          let res = await Auth.loginNative({
            data: encodeURIComponent(sesecurityStr)
          })
          if (!res['code']) {
            this.$cookie.set('user', true)
            this.$store.dispatch('user', true)
            this.getUserInfo()
          } else {
            this.$message.error(this.errMsg[res['code']])
          }
          this.btnLoading = false
        } else {
          return false;
        }
      })
    },
    async logOut () {
      let res = await Auth.logout()
      if (!res['code']) {
        this.$cookie.delete('user')
        this.$store.dispatch('user', null)
        if (res['data']) {
          window.location.href = res['data']
        } else {
          this.$router.push('/login')
        }
        // console.log(this.config.api + 'login/cas?targetUrl=' + encodeURIComponent(window.location.href))
        // window.location.href = this.config.api + 'login/cas?targetUrl=' + encodeURIComponent(window.location.href)
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    getTimeFn () {
      let date = new Date()
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let fullMonth = month < 10 ? '0' + month : month
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      let hours = date.getHours()
      let fullHours = hours < 10 ? '0' + hours : hours
      let minutes = date.getMinutes()
      let fullMinutes = minutes < 10 ? '0' + minutes : minutes
      let seconds = date.getSeconds()
      let fullSeconds = seconds < 10 ? '0' + seconds : seconds
      let time = String(year) + String(fullMonth) + String(day) + String(fullHours) + String(fullMinutes) + String(fullSeconds)
      return time
    },
    async getUserInfo () {
      let res = await Auth.getUserinfo()
      if (!res['code']) {
        this.$cookie.set('user', res['data'])
        this.$store.dispatch('user', res['data'])
        let menu = {
          data: {}
        }
        if (res['data']['privilege']['user']) { // 人员管理
          for (let key in res['data']['privilege']['user']) {
            let item = res['data']['privilege']['user'][key]
            if (item.privilege === '*') {
              this.$store.dispatch('userModify', true)
              this.$store.dispatch('userAccountModify', true)
              break
            } else {
              if (item.privilege && item.privilege.length) {
                for (let k in item.privilege) {
                  if (item.privilege[k].indexOf('modify') > -1) {
                    this.$store.dispatch('userModify', true)
                  }
                  if (item.privilege[k].indexOf('account/modify') > -1) {
                    this.$store.dispatch('userAccountModify', true)
                  }
                }
              }
            }
          }
          // console.log('userModify', this.userModify)
          // console.log('userAccountModify', this.userAccountModify)
          for (let key in res['data']['privilege']['user']) {
            let item = res['data']['privilege']['user'][key]
            if (item.privilege === '*') {
              menu.data['user'] = [
                '/user/index',
                '/user/batchuser',
                '/user/batchsearch',
                '/user/batchaccount',
                '/user/batchuseraccount'
              ]
              break
            }
            for (let k in item.privilege) {
              if (item.privilege[k].length === 1 && (item.privilege[k][0] === 'query' || item.privilege[k][0] === 'query_mg')) {
                menu.data['user'] = [
                  '/user/index',
                  '/user/batchuser',
                  '/user/batchsearch',
                  '/user/batchaccount',
                  '/user/batchuseraccount'
                ]
                
                break
              }
            }
          }
        }
        if (res['data']['privilege']['service']) { // 系统接入
          menu.data['access'] = [
            '/access/index'
          ]
        }
        if (res['data']['privilege']['groups']) { // 组织机构
          menu.data['group'] = []
          if (res['data']['privilege']['groups']['privilege'] === '*') {
            menu.data['group'] = [
              '/group/department',
              '/group/tag',
              '/group/usertype',
              '/group/filters'
            ]
          } else {
            for (let key in res['data']['privilege']['groups']['privilege']) {
              let item = res['data']['privilege']['groups']['privilege'][key]
              if (item[0] === 'department') {
                menu.data['group'].push('/group/department')
              } else if (item[0] === 'tag') {
                menu.data['group'].push('/group/tag')
              } else if (item[0] === 'usertype') {
                menu.data['group'].push('/group/usertype')
              } else if (item[0] === 'filter') {
                menu.data['group'].push('/group/filters')
              }
            }
          }
        }
        if (res['data']['privilege']['statistic/query']) { // 系统管理-统计分析
          if (!menu.data['system']) {
            menu.data['system'] = []
          }
          if (res['data']['privilege']['statistic/query']['privilege'] === '*') {
            menu.data['system'].push('/system/analysis/index')
            menu.data['system'].push('/system/analysis/face')
            menu.data['system'].push('/system/analysis/login')
            menu.data['system'].push('/system/analysis/active')
            menu.data['system'].push('/system/analysis/ip')
          } else {
            for (let key in res['data']['privilege']['statistic/query']['privilege']) {
              let item = res['data']['privilege']['statistic/query']['privilege'][key]
              if (item[0] === 'general') {
                menu.data['system'].push('/system/analysis/index')
              } else if (item[0] === 'facerecognition') {
                menu.data['system'].push('/system/analysis/face')
              } else if (item[0] === 'login') {
                menu.data['system'].push('/system/analysis/login')
              } else if (item[0] === 'serviceaccess') {
                menu.data['system'].push('/system/analysis/active')
              } else if (item[0] === 'ipaccess') {
                menu.data['system'].push('/system/analysis/ip')
              }
            }
          }
        }
        if (res['data']['privilege']['authorization']) { // 系统管理-权限管理
          if (!menu.data['system']) {
            menu.data['system'] = []
          }
          if (res['data']['privilege']['authorization']['privilege'] === '*') {
            menu.data['system'].push('/system/power/index')
            menu.data['system'].push('/system/power/roles')
          } else {
            for (let key in res['data']['privilege']['authorization']['privilege']) {
              let item = res['data']['privilege']['authorization']['privilege'][key]
              if (item[0] === 'user') {
                menu.data['system'].push('/system/power/index')
              } else if (item[0] === 'role') {
                menu.data['system'].push('/system/power/roles')
              }
            }
          }
        }
        if (res['data']['privilege']['app']) { // 系统管理-接口管理
          if (!menu.data['system']) {
            menu.data['system'] = []
          }
          menu.data['system'].push('/system/interface/index')
          menu.data['system'].push('/system/interface/statistics')
          menu.data['system'].push('/system/interface/api')
          menu.data['system'].push('/system/interface/add')
        }
        if (res['data']['privilege']['config']) { // 系统管理-系统配置
          if (!menu.data['system']) {
            menu.data['system'] = []
          }
          if (res['data']['privilege']['config']['privilege'] === '*') {
            menu.data['system'].push('/system/configuration/index')
            menu.data['system'].push('/system/configuration/basic')
            menu.data['system'].push('/system/configuration/accesspolicy')
          } else {
            for (let key in res['data']['privilege']['config']['privilege']) {
              let item = res['data']['privilege']['config']['privilege'][key]
              if (item[0] === 'securitypolicy') {
                menu.data['system'].push('/system/configuration/index')
              } else if (item[0] === 'basicsetting') {
                menu.data['system'].push('/system/configuration/basic')
              } else if (item[0] === 'accesspolicy') {
                menu.data['system'].push('/system/configuration/accesspolicy')
              }
            }
          }
        }
        if (res['data']['privilege']['backup']) { // 系统管理-数据备份
          if (!menu.data['system']) {
            menu.data['system'] = []
          }
          if (res['data']['privilege']['backup']['privilege'] === '*') {
            menu.data['system'].push('/system/backup/index')
            menu.data['system'].push('/system/backup/external')
            menu.data['system'].push('/system/backup/importlog')
            menu.data['system'].push('/system/backup/export')
            menu.data['system'].push('/system/backup/import')
            menu.data['system'].push('/system/backup/externallog')
          } else {
            for (let key in res['data']['privilege']['backup']['privilege']) {
              let item = res['data']['privilege']['backup']['privilege'][key]
              if (item[0] === 'sysdata') {
                menu.data['system'].push('/system/backup/index')
                menu.data['system'].push('/system/backup/importlog')
                menu.data['system'].push('/system/backup/export')
                menu.data['system'].push('/system/backup/import')
              } else if (item[0] === 'external') {
                menu.data['system'].push('/system/backup/external')
                menu.data['system'].push('/system/backup/externallog')
              }
            }
          }
        }
        if (res['data']['privilege']['other']) { // 系统管理-其它设置
          if (!menu.data['system']) {
            menu.data['system'] = []
          }
          if (res['data']['privilege']['other']['privilege'] === '*') {
            menu.data['system'].push('/system/other/message')
            menu.data['system'].push('/system/other/task')
          } else {
            for (let key in res['data']['privilege']['other']['privilege']) {
              let item = res['data']['privilege']['other']['privilege'][key]
              if (item[0] === 'notification') {
                menu.data['system'].push('/system/other/message')
              } else if (item[0] === 'task') {
                menu.data['system'].push('/system/other/task')
              }
            }
          }
        }
        // if (res['data']['privilege']['log']) { // 日志审计
        //   menu.data['logaudit'] = [
        //     '/logaudit/index'
        //   ]
        // }
        if (res['data']['privilege']['log']) { // 日志审计
          if (!menu.data['logaudit']) {
            menu.data['logaudit'] = []
          }
          menu.data['system'].push('/logaudit/index')
          if (res['data']['privilege']['log']['privilege'] === '*') {
            menu.data['logaudit'].push('/logaudit/audit')
            menu.data['logaudit'].push('/logaudit/login')
            menu.data['logaudit'].push('/logaudit/face')
          } else {
            for (let key in res['data']['privilege']['log']['privilege']) {
              let item = res['data']['privilege']['log']['privilege'][key]
              if (item[0] === 'audit/query') {
                menu.data['logaudit'].push('/logaudit/audit')
              } else if (item[0] === 'login/query') {
                menu.data['logaudit'].push('/logaudit/login')
              } else if (item[0] === 'facerecognition/query') {
                menu.data['logaudit'].push('/logaudit/face')
              }
            }
          }
        }
        if (res['data']['privilege']['publish']) { // 资讯发布
          menu.data['news'] = [
            '/news/index'
          ]
        }
        if (res['data']['decentralization']) { // 二级授权
          menu.data['decentralization'] = [
            '/decentralization/index'
          ]
        }
        menu['data']['permission'] = ['/permission']
        menu['data']['permission2'] = ['/permission2']
        menu['data']['login'] = ['/login']
        this.getMenu(menu)
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getMenu (res) {
      // let res1 = await Auth.getMenu()
      // console.log(res1)
      // console.log('11', this.$route.path)
      // console.log('menu', res['data'])
      this.$store.dispatch('menu', res['data'])
      // let route = this.$route.path.split('/')[1]
      let route = this.$route.path
      let routeCode = null
      for (let key in this.menu) {
        if (this.menu[key].indexOf(route) > -1) {
          routeCode = true
          break
        }
      }
      // let menuItem = res['data'].find(item => {
      //   return item.id === routeCode
      // })
      if (!routeCode) {
        for (let k in this.menu) {
          this.$router.push(this.menu[k][0])
          break
        }
      } else {
        if (route === '/permission') {
          for (let k in this.menu) {
            this.$router.push(this.menu[k][0])
            break
          }
        }
      }
      this.vueRouterHandle()
    },
    vueRouterHandle () {
      this.$router.beforeEach((to, from, next) => {
        if (to.path === '/group/department') {
          if (this.menu['group']) {
            if (this.menu['group'].indexOf(to.path) > -1) {
              next()
            } else {
              this.$router.push(this.menu['group'][0])
              next()
            }
          } else {
            this.$message.error('您没有权限访问该页面！')
          }
        } else if (to.path === '/system/analysis/index') {
          if (this.menu['system']) {
            if (this.menu['system'].indexOf(to.path) > -1) {
              next()
            } else {
              this.$router.push(this.menu['system'][0])
              next()
            }
          } else {
            this.$message.error('您没有权限访问该页面！')
          }
        } else if (to.path === '/logaudit/login') {
          if (this.menu['logaudit']) {
            if (this.menu['logaudit'].indexOf(to.path) > -1) {
              next()
            } else {
              this.$router.push(this.menu['logaudit'][0])
              next()
            }
          } else {
            this.$message.error('您没有权限访问该页面！')
          }
        } else {
          let routeStatus = false
          for (let key in this.menu) {
            if (this.menu[key].indexOf(to.path) > -1) {
              routeStatus = true
            }
          }
          if (!routeStatus) {
            this.$message.error('您没有权限访问该页面！')
          } else {
            next()
          }
        }
      })
    },
    checkArrayHas (val, arr) {
      if (!arr.length) return false
      let status = false
      for (let key in arr) {
        for (let k in arr[key]) {
          if (arr[key][k] === val) {
            status = true
            break
          }
        }
      }
      return status
    },
    checkArrayHasTag (arr) {
      if (!arr.length) return false
      let status = false
      for (let key in arr) {
        if (arr[key].length === 1) {
          status = true
          break
        }
      }
      return status
    }
    // async getMenu () {
    //   let res = await Auth.getMenu()
    //   if (!res['code']) {
    //     // this.menu = res['data']
    //     this.$store.dispatch('menu', res['data'])
    //     // this.menu = [{'id': '02000000'}]
    //     let route = this.$route.path.split('/')[1]
    //     // console.log(route)
    //     let routeCode = null
    //     for (let key in this.menuRoute) {
    //       if (this.menuRoute[key] === route) {
    //         routeCode = key
    //         break
    //       }
    //     }
    //     let menuItem = res['data'].find(item => {
    //       return item.id === routeCode
    //     })
    //     if (!menuItem) {
    //       this.$router.push('/' + this.menuRoute[res['data'][0]['id']])
    //     }
    //   } else {
    //     this.$message.error(this.errMsg[res['code']])
    //   }
    // }
  },
  created () {
    // console.log('appCreated')
    // console.log('errMsg', this.errMsg)
    this.$router.onReady(() => {
      // console.log(this.$route.path)
      if (this.$route.path !== '/login') {
        this.getUserInfo()
      }
      this.router = this.$route.path
    })
    document.title = this.baseConfig.title
  }
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
body,html,#app {
  width: 100%;
  height: 100%;
  font-family: Roboto,Helvetica Neue,"PingFang SC","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  font-size: 14px;
  overflow-x: hidden;
  overflow-y: hidden;
  color: #606266;
  background: #f9f9f9;
}
img {
  display: block;
}
a{
  text-decoration: none;
}
.alink{
  color: #1890FF;
}
.adelete{
  color: #E02020;
}
a.underline {
  text-decoration: underline;
}
.auto {
  height: auto;
  overflow: hidden;
}
.fl {
  float: left;
}
.fr {
  float: right;
}

.font12 {
  font-size: 12px;
}
.font14 {
  font-size: 14px;
}
.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 18px;
}

.m-r-5 {
  margin-right: 5px!important;
}
.m-r-10 {
  margin-right: 10px!important;
}
.m-t-5 {
  margin-top: 5px!important;
}
.m-t-10 {
  margin-top: 10px!important;
}
.m-t-20 {
  margin-top: 20px!important;
}
.m-l-10 {
  margin-left: 10px!important;
}

.p-tb-10 {
  padding: 10px 0;
}

.greyccc {
  color: #ccc!important;
}
.grey666 {
  color: #666!important;
}
.grey999 {
  color: #999!important;
}
.ellipsis{
  text-overflow: ellipsis;
  white-space: nowrap;
}

.colorfff {
  color: #fff!important;
}
.bgred {
  background: #E02020!important;
}
.bggreen {
  background: #6DD400!important;
}
.bgyellow {
  background: #F7B500!important;
}
.bgblue {
  background: #32C5FF!important;
}
.borred {
  border: 1px solid #E02020!important;
}
.borgreen {
  border: 1px solid #6DD400!important;
}
.boryellow {
  border: 1px solid #F7B500!important;
}
.borblue {
  border: 1px solid #32C5FF!important;
}

.icon-circle {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  display: inline-block;
  overflow: hidden;
  margin-right: 5px;
  margin-bottom: 1px;
}

.login {
  width: 100%;
  height: 100%;
  position: relative;
}
.login-content {
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.35);
  margin-bottom: 20px;
  padding-bottom: 30px;
  width: 520px;
  position: absolute;
  top: 20%;
  left: 50%;
  margin-left: -260px;
  overflow: hidden;
}
.login-content h3 {
  display: block;
  width: 100%;
  height: 60px;
  background: #409EFF;
  font-size: 18px;
  text-align: center;
  line-height: 60px;
  color: #fff;
}
.login-content p {
  text-align: center;
  font-size: 16px;
  color: #333;
  font-weight: bold;
  padding: 10px 0;
}
.login-title {
  color: #505458;
  margin: 0 auto 20px;
  text-align: center;
}

#app .content {
  height: 100%;
  background: #f9f9f9;
}
#app .ant-layout-header {
  height: 70px;
  background: none;
  padding: 0;
}
.header {
  background: #001529;
  height: 30px;
  line-height: 30px;
  padding: 0;
}
.logo {
  margin-left: 28px;
}
.uesr-info {
  margin-right: 28px;
  color: #fff;
}
/*.uesr-info span {
  margin-right: 20px;
}
.uesr-info span a {
  margin-right: 20px;
}
.uesr-info a {
  color: #fff;
}*/
.nav {
  text-align: center;
  height: 40px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
  background: #fff;
}
.nav ul {
  line-height: 38px;
}
.nav ul li {
  display: inline-block;
  height: 38px;
  line-height: 38px;
  margin: 0 30px;
}
.nav ul li a {
  font-size: 16px;
  display: inline-block;
  padding: 0 24px;
  color: rgba(0,0,0,0.65);
}
.nav ul li a.current {
  color: #1890FF;
  border-bottom: 2px solid #1890FF;
}

.ant-layout-content {
  height: 100%;
  margin: 10px;
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.08);
}

#app .custom-layout {
  background: none;
  height: 100%;
  position: relative;
}
#app .back-btn {
  position: absolute;
  left: 20px;
  top: 15px;
  width: 70px
}
#app .back-btn span {
  margin-left: 5px;
}

#app .custom-sider {
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.08);
  border-right: 1px solid rgba(0,0,0,0.06);
  height: 100%;
}
#app .ant-form-item-label {
  line-height: 30px;
}
#app .ant-form-item-control {
  line-height: 30px;
}
#app .custom-content {
  padding: 10px 24px;
  margin: 0;
  overflow-y: auto;
  box-sizing: border-box;
}

.sider-title {
  padding: 22px 20px 10px;
  line-height: 16px;
}
.coustom-title {
  font-size: 16px;
  font-weight: bold;
}

.content-title {
  padding: 8px 0 0;
  line-height: 24px;
}
.coustom-title-mes {
  background: #E6F7FF;
  border: 1px solid #91D5FF;
  border-radius: 2px;
  border-radius: 2px;
  line-height: 22px;
  padding: 0 10px;
}


#drawer-form .ant-form-item-label {
  width: 100px;
}
#drawer-form input {
  /*width: 156px;*/
  /*width: 90%;*/
}
#drawer-form .ant-select {
  /*width: 156px;*/
  /*width: 90%;*/
}
.form-item-100 {
  width: 100%;
}
#drawer-form .ant-form-item-control-wrapper {
  width: calc(100% - 100px);
}

#log-search-bar .ant-form-item-label {
  width: 70px;
}
#logaudit-search-bar .ant-form-item-label {
  width: 100px;
}
#log-search-bar .ant-advanced-search-form .ant-form-item {
  margin-bottom: 0;
}
#log-search-bar .ant-calendar-picker {
  width: 200px;
}
#log-search-bar .ant-select {
  width: 150px;
}

.department-content {
  /*max-width: 860px;*/
}

#app .access-drawer .ant-drawer-body {
  height: 100%;
  padding: 0;
}

#no-margin-form .ant-form-item {
  margin-bottom: 0;
}
#no-margin-form2 .ant-form-item {
  margin-bottom: 15px;
}
#news-margin-form .ant-form-item {
  margin-bottom: 7px;
}
.custom-news-item .ant-form-item-control {
  line-height: 16px!important;
}

#app .custom-el-cascader .el-input--small .el-input__inner, #power-roles-dialog .custom-el-cascader .el-input--small .el-input__inner{
  height: 24px!important;
  line-height: 24px!important;
}
#app .custom-el-cascader .el-input__inner, #power-roles-dialog .custom-el-cascader .el-input__inner{
  padding: 0 7px!important;
  font-size: 14px!important;
}
#app .custom-el-cascader .el-cascader__search-input, #power-roles-dialog .custom-el-cascader .el-cascader__search-input {
  margin-left: 8px!important;
}
#app .custom-el-cascader .el-input__suffix, #power-roles-dialog .custom-el-cascader .el-input__suffix {
  right: 2px!important;
}
#app .custom-el-cascader input, #power-roles-dialog .custom-el-cascader input {
  height: 24px!important;
}
.custom-el-cascader .el-icon-arrow-down {
  transform: scale(0.9, 1.25)!important;
}
/*.el-tag--mini {
  height: 16px!important;
  line-height: 16px!important;
}*/
#app .custom-el-cascader-no-max input {
  /*height: auto!important;*/
}
#app .custom-el-cascader .el-tag--mini {
  /*height: 14px;
  line-height: 14px;*/
}
#app .el-cascader__tags .el-tag, #power-roles-dialog .el-cascader__tags .el-tag, #no-margin-form2 .el-cascader__tags .el-tag {
  background: #fafafa!important;
  border: 1px solid #d9d9d9!important;
}
#app .el-cascader__tags .el-tag .el-icon-close, #power-roles-dialog .el-cascader__tags .el-tag .el-icon-close, #no-margin-form2 .el-cascader__tags .el-tag .el-icon-close {
  background: none!important;
  color: rgba(0,0,0,.45)!important;
  font-size: 12px!important;
  transform: scale(1)!important;
  top: 0!important;
}
#app .custom-el-cascader .el-input--mini .el-input__inner, #power-roles-dialog .custom-el-cascader .el-input--mini .el-input__inner {
  line-height: 24px!important;
}
#app .custom-el-cascader .el-cascader__search-input, #power-roles-dialog .custom-el-cascader .el-cascader__search-input {
  font-size: 14px!important;
}
#app .custom-el-cascader .el-cascader__tags input, #power-roles-dialog .custom-el-cascader .el-cascader__tags input {
  height: 20px!important;
}
/*#power-roles-dialog .ant-select-sm .ant-select-selection--single {
  height: 27px!important;
}*/
/*#custom-el-cascader2, #custom-el-cascader3, #custom-el-cascader4 {
  transform: scaleY(0.8);
}
#custom-el-cascader2 .el-cascader__tags .el-tag, #custom-el-cascader3 .el-cascader__tags .el-tag, #custom-el-cascader4 .el-cascader__tags .el-tag {
  transform: scaleY(1.2);
}
#custom-el-cascader2 .el-cascader__search-input {
  transform: scaleY(1.2);
}*/

.ant-spin-container {
  height: 100%;
}


.ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th, .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td, .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td, .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody > tr > td, .ant-table-middle > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td, .ant-table-middle > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-tbody > tr > td {
  padding: 6px 8px 6px 15px!important;
  height: 30px!important;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 6px 8px 6px 15px!important;
  height: 30px!important;
}
.ant-btn-sm {
  border-radius: 2px!important;
}
.ant-table {
  line-height: 1.2!important;
}
#app .ant-table-tbody > tr > td {
  padding: 3px 8px 3px 15px!important;
  height: 30px!important;
}
.ant-popover {
  margin-top: -6px!important;
}
.ant-popover-inner-content-custom .ant-popover-inner-content {
  padding: 5px!important;
}
.ant-popover-inner-content {
  padding: 10px 16px!important;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 2px 0 0 2px!important;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 2px 2px 0!important;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  border-radius: 4px!important;
}
.ant-select-selection--multiple .ant-select-selection__choice__content {
  font-size: 12px;
}

#access-form .ant-form-item-label {
  width: 170px;
}
#access-form .ant-col-21 {
  width: calc(100% - 90px);
}
#filter-form .ant-form-item-label {
  width: 85px;
}
#filter-form .ant-form-item-control-wrapper {
  width: calc(100% - 85px);
}
#filter-form .ant-col-24 {
  width: 100%;
}

/*系统接入白名单样式*/
.drawer-content-warp .ant-table-selection-column {
  width: 40px!important;
  padding: 6px 0!important;
}
.drawer-content-warp .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding-left: 0!important;
}
#whitebox .ant-table-tbody > tr > td, #blackbox .ant-table-tbody > tr > td {
  padding: 3px 0!important;
}
.drawer-content-warp .ant-radio {
  padding-top: 7px;
}

.uesr-info .user-info-span {
  margin-right: 10px;
}
.uesr-info .user-info-span a.downloadlink {
  margin-right: 10px;
  color: #fff;
}

.el-scrollbar__bar {
  opacity: 1!important;
}
</style>
