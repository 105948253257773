<template>
  <div class="system-menu auto">
    <dl>
      <!-- <dt>其它设置</dt> -->
      <dd v-if="menu['logaudit'].indexOf('/logaudit/login') > -1"><router-link to="/logaudit/login" :class="path === '/logaudit/login' && 'cur'">统一认证</router-link></dd>
      <dd v-if="menu['logaudit'].indexOf('/logaudit/face') > -1"><router-link to="/logaudit/face" :class="path === '/logaudit/face' && 'cur'">人脸识别</router-link></dd>
      <dd v-if="menu['logaudit'].indexOf('/logaudit/audit') > -1"><router-link to="/logaudit/audit" :class="path === '/logaudit/audit' && 'cur'">管理员操作</router-link></dd>
    </dl>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LogMenu',
  data () {
    return {
      path: 'index'
    }
  },
  watch: {
    '$route': function (val) {
      this.path = val.path
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'menu'
    ])
  },
  methods: {
  },
  created () {
    this.path = this.$route.path
  }
}
</script>

<style scoped>
.system-menu {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.system-menu dl dt {
  font-size: 20px;
  color: rgba(0,0,0,0.25);
  text-indent: 42px;
  font-weight: bold;
  line-height: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
}
.system-menu dl dd{
  width: 100%;
  margin: 0;
}
.system-menu dl dd a {
  display: inline-block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-indent: 42px;
  font-size: 16px;
  color: rgba(0,0,0,0.65);
  margin: 0;
}
/*.system-menu dl dd a:hover {
  background: #E6F7FF;
}*/
.system-menu dl dd a.cur {
  background: #E6F7FF;
  color: rgba(0,0,0,0.85);
  font-weight: bold;
}
</style>
